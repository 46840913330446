import React, { useState, useRef, useEffect } from "react";
import Project from "./Project";
import { subscribe, unsubscribe } from "../Hooks/Events";
import { useSprings, useSpring, animated } from "react-spring";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useMediaQuery from "../Hooks/MatchMedia";

const Category = ({ category, projects }) => {
  //Set the project titles to all be...
  const mobile = useMediaQuery("(min-width: 768px)");
  const project_title_height = mobile ? 100 : 80;

  const pic = mobile
    ? getImage(category.image)
    : getImage(category.image_mobile);
  const catRef = useRef(null);
  const detailsRef = useRef(null);
  const [expand, isExpanded] = useState(false);
  //Category details animated height
  const [height, setHeight] = useState("0px");
  //What id is being shown
  const [showId, setShowId] = useState();

  //Get the is expanded event subscribed to for each instance...
  useEffect(() => {
    function debounceAddHeight() {
      if (catRef.current.classList.contains("expand")) {
        isExpanded(true);
        setHeight(detailsRef.current.scrollHeight + "px");
      } else {
        isExpanded(false);
        setHeight("0px");
        setShowId("");
      }
    }
    subscribe("expanded", () => {
      debounceAddHeight();
    });

    return () => {
      unsubscribe("expanded", () => {
        debounceAddHeight();
      });
    };
  }, [catRef]);

  //Category description spring
  const details = useSpring({ height: height });

  //This happens before the project files slide down. I want them simultaneously...
  const containerSpring = useSpring({
    immediate: true,
    config: { duration: 0 },
    to: { height: expand ? "100%" : "0%" },
    from: { height: expand ? "0%" : "100%" },
  });

  //Animation sent to the .map() inside Project
  const springs = useSprings(
    projects.length,
    //not using any data from projects yet. Should I get the client bounding rect? No
    projects.map((project, i) => ({
      config: { duration: 350 },
      to: {
        height: expand ? `${project_title_height}px` : "0px",
        top: expand ? "0px" : `${-project_title_height * (i + 1)}px`,
      },
    }))
  );

  return (
    <div className="category" ref={catRef} data-category={category.title}>
      <div style={{ position: "relative" }}>
        <div className="category-image">
          <GatsbyImage
            image={pic}
            alt="the category image"
            imgStyle={{ minHeight: "100%" }}
          />
        </div>
        <div className="category-overlay">
          <div className="category-container">
            <h1>{category.title}</h1>
            <animated.div
              className="category-text"
              ref={detailsRef}
              style={details}
            >
              <h2>{category.body}</h2>
              <br />
              <p style={{ textAlign: "end", fontSize: "0.8rem" }}>
                <em>Click on any of the projects below to see more.</em>
              </p>
            </animated.div>
          </div>
        </div>
      </div>
      {/* From here on it's the project list... */}
      <animated.div
        style={{
          ...containerSpring,
          overflow: "hidden",
        }}
      >
        {springs.map((spring, index) => {
          const projectCategory = projects[index].node.frontmatter.category;
          //Introduce the id and set it
          const handleShow = () => {
            //Get the project index (which is its title and add it to state (which is shared with the project components))
            setShowId(projects[index].node.frontmatter.title);
          };

          if (projectCategory.includes(category.title)) {
            return (
              <Project
                spring={spring}
                project={projects[index]}
                key={index}
                show={showId === projects[index].node.frontmatter.title}
                onShow={handleShow}
              />
            );
          } else return null;
        })}
      </animated.div>
    </div>
  );
};

export default Category;
