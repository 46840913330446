import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { MainLogo } from "../../static/assets/Logo";
import { About, Contacts } from "../components/sections";
import { Projects } from "../components/project-sections/Projects-List";
import useMediaQuery from "../components/Hooks/MatchMedia";

const AboveTheFold = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;

  .logo-container {
    position: absolute;
    z-index: 3;
    max-width: 50%;
    margin: auto;
    top: 20%;
    left: 20%;
    right: 20%;
    bottom: 20%;
  }

  .alt-container {
    position: absolute;
    z-index: 2;
    bottom: 1rem;
    right: 4rem;
  }

  @media (max-width: 768px) {
    .alt-container {
      right: 1rem;
      bottom: 0.5rem;
    }
  }
`;

const IndexPage = ({ data }) => {
  const media = useMediaQuery("(min-width: 850px)");
  const { heading, categories } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter;
  const body = data.allFile.edges[0].node.childMarkdownRemark.html;

  //Sort the array so that it's in date order.
  function compare(a, b) {
    var dateA = new Date(a.node.frontmatter.date).getTime();
    var dateB = new Date(b.node.frontmatter.date).getTime();

    if (dateA < dateB) return 1;
    if (dateA > dateB) return -1;
    return 0;
  }

  const projects = data.allMarkdownRemark.edges.sort(compare);

  return (
    <Layout>
      <Seo title="Home" />
      <AboveTheFold>
        {media ? (
          <StaticImage
            src={"../../static/assets/Antarctica250m-desktop.jpg"}
            formats={["auto", "webp"]}
            placeholder={"blurred"}
            style={{
              height: "100vh",
              width: "100%",
              position: "absolute",
              zIndex: "1",
            }}
            alt="Icebergs on the Ross Sea, Antarctica / Courtesy of NASA"
            transformOptions={{
              fit: "cover",
            }}
          />
        ) : (
          <StaticImage
            src={"../../static/assets/Antarctica250m-mobile.jpg"}
            formats={["auto", "webp"]}
            placeholder={"blurred"}
            style={{
              height: "100vh",
              width: "100%",
              position: "absolute",
              zIndex: "1",
            }}
            alt="Icebergs on the Ross Sea, Antarctica / Courtesy of NASA"
            transformOptions={{
              fit: "cover",
            }}
          />
        )}
        <div className="logo-container">
          <MainLogo />
        </div>
        <div className="alt-container">
          <h6>Icebergs on the Ross Sea, Antarctica / Courtesy of NASA</h6>
        </div>
      </AboveTheFold>
      <About heading={heading} body={body} />
      <Projects categories={categories} projects={projects} />
      <Contacts />
    </Layout>
  );
};

export const query = graphql`
  {
    allFile(filter: { name: { eq: "home" } }) {
      edges {
        node {
          childMarkdownRemark {
            html
            frontmatter {
              heading
              categories {
                title
                body
                image {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
                image_mobile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { cms: { eq: "project" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            date
            title
            budget
            awards
            category
            location
            video {
              publicURL
            }
            client
            image_list {
              image_1 {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    height: 800
                  )
                }
              }
              image_2 {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    height: 800
                  )
                }
              }
              image_3 {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    height: 800
                  )
                }
              }
              image_4 {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    height: 800
                  )
                }
              }
              image_5 {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    height: 800
                  )
                }
              }
              image_6 {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    height: 800
                  )
                }
              }
              image_7 {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    height: 800
                  )
                }
              }
              image_8 {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    height: 800
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
