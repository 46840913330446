import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const About = ({ heading, body }) => {
  //const [expand, isExpanded] = useState(false);
  return (
    <div id={"about"}>
      <div className="container">
        <h1
          style={{
            textTransform: "uppercase",
            whiteSpace: "pre-wrap",
            letterSpacing: "0.1rem",
            textAlign: "center",
          }}
        >
          {heading}
        </h1>

        <div
          dangerouslySetInnerHTML={{ __html: body }}
          style={{ whiteSpace: "pre-wrap", padding: "4rem 0" }}
        />
      </div>
    </div>
  );
};

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export const Contacts = () => {
  const [details, setDetails] = useState({
    name: "",
    email: "",
    message: "",
    thanks: false,
  });
  const reCaptcha = useRef();

  const [thanks, setThanks] = useState(false);

  const [errors, setErrors] = useState({
    errorName: "",
    errorEmail: "",
    errorCaptcha: "",
  });

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    setErrors({ errorName: "", errorEmail: "", errorCaptcha: "" });
  };

  function handleValidation() {
    let formIsValid = true;
    let name = details.name;
    let email = details.email;
    let lastAtPos = details.email.lastIndexOf("@");
    let lastDotPos = details.email.lastIndexOf(".");
    const recaptchValue = reCaptcha.current.getValue();

    if (!recaptchValue) {
      formIsValid = false;
      setErrors({ errorCaptcha: "Please complete security" });
    }

    if (!name) {
      formIsValid = false;
      setErrors({ errorName: "This cannot be empty" });
    }

    if (!email) {
      formIsValid = false;
      setErrors({ errorEmail: "This cannot be empty" });
    } else if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        details.email.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        details.email.length - lastDotPos > 2
      )
    ) {
      formIsValid = false;
      setErrors({ errorEmail: "Email is not valid" });
    }

    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...details }),
      })
        .then(() => setThanks(!thanks))
        .catch((error) => alert(error));
    }
  };

  return (
    <div className="container" id="contact">
      <h1 style={{ textAlign: "center", padding: "4rem 0" }}>Contact</h1>
      <div>
        <div>
          {thanks ? (
            <div style={{ margin: "auto", textAlign: "center" }}>
              <h2>Thanks!</h2>
              <br />
              <h2>Someone will be in touch.</h2>
            </div>
          ) : (
            <form data-netlify="true" name="contact" onSubmit={handleSubmit}>
              <input type="hidden" name="form-name" value="contact" />

              <div className="input-box">
                <input
                  type="text"
                  name="name"
                  className="float-button"
                  placeholder="Name:"
                  onChange={handleChange}
                />
                <label>{errors.errorName}</label>
              </div>
              <div className="input-box">
                <input
                  type="text"
                  className="float-button"
                  name="email"
                  placeholder="Email:"
                  onChange={handleChange}
                />
                <label>{errors.errorEmail}</label>
              </div>
              <div className="input-box">
                <textarea
                  type="text"
                  name="message"
                  className="float-button"
                  placeholder="Message:"
                  rows="5"
                  onChange={handleChange}
                />
              </div>
              <div className="input-box end-grid align-right recaptcha">
                <ReCAPTCHA
                  sitekey={process.env.SITE_RECAPTCHA_KEY}
                  ref={reCaptcha}
                />
                <label>{errors.errorCaptcha}</label>
              </div>
              <div className="input-box end-grid">
                <button
                  className="float-button align-right"
                  style={{ textAlign: "center" }}
                >
                  Send
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
