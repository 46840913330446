import React from "react";

export const Caret = () => {
  return (
    <svg
      width="auto"
      height="100%"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 30.1062L1.23023e-06 24.1062L17.8222 15L2.26592e-07 6.1062L1.31142e-06 0.106201L30 15L0 30.1062Z"
        fill="black"
      />
    </svg>
  );
};
