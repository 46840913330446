import React, { useEffect, useRef } from "react"
import { closestItem } from "../Hooks/closestTo"
import Category from "./Category"
import { publish } from "../Hooks/Events"
import "./project.css"
import { isInViewport } from "../Hooks/ViewFunctions"

export const Projects = ({ categories, projects }) => {
  const list = useRef(null)

  //Find the item in the middle of the screen and apply expand class, remove the expand class from anything else...
  useEffect(() => {
    const listItems = list.current.childNodes
    // Convert buttons NodeList to an array
    let itemsArr = Array.from(listItems)

    function middleOfList() {
      // Create an array of the tops of each element
      const tops = []
      for (const item of listItems) {
        tops.push(
          //this should be the center??
          item.getBoundingClientRect().top +
            item.getBoundingClientRect().height / 2
        )
      }
      //Check which element is closest to the middle of the screen (only one!) and return its index
      const middleItemIndex = closestItem(window.innerHeight / 2, tops)

      //Check if the index is the same as the middle item
      itemsArr.forEach((item, i) => {
        //Add expand to the middle item
        //changed to in viewport, to restore (i === middleItemIndex)

        if (i === middleItemIndex) {
          item.classList.add("expand")
          publish("expanded")
          //If the item is no longer
        } else if (!isInViewport(item)) item.classList.remove("expand")
      })
    }

    function onClickList(event) {
      //If the thing that was just clicked doesn't/isn't a parent that contains 'expand class'
      //this is a new category, so close everything and then open this one and publish expand
      if (
        !event.target.closest(".expand") ||
        !event.target.closest(".ReactModalPortal")
      ) {
        itemsArr.forEach((item, i) => {
          if (
            //If the item clicked category matches the item category from forEach...
            item.dataset["category"] ===
            event.target.closest(".category").dataset["category"]
          ) {
            item.classList.add("expand")
            publish("expanded")
            // scrollToPosY(item)
          } else item.classList.remove("expand")
        })
      } else return
    }

    //Test to see if a category or child-of has been clicked
    list.current.addEventListener("click", event => {
      window.removeEventListener("wheel", middleOfList)
      onClickList(event)
    })

    window.addEventListener("wheel", () => {
      middleOfList()
    })

    return () => {
      window.removeEventListener("wheel", middleOfList)
    }
  })

  //Returning a map of category JSX means it can contain state for each individual element...
  return (
    <div className="category-list" ref={list} id="projects">
      {categories.map((category, i) => {
        return <Category projects={projects} category={category} key={i} />
      })}
    </div>
  )
}
