import React, { useRef, useState } from "react";
import { Caret } from "../../../static/assets/icons/caret";
import Concertina from "../UI/concertina";
import { animated } from "react-spring";
import Carousel from "../UI/image-carousel";

const Project = ({ spring, project, show, onShow }) => {
  const projectRef = useRef(null);
  //Need this to doublecheck if the project is already open...
  const [toggle, setToggled] = useState(false);

  //This function returns truthy...show?
  const handleShow = () => {
    setToggled(!toggle);
    if (onShow && onShow(project.node.frontmatter.title) && toggle) return true;
  };

  return (
    <div className="project-box" ref={projectRef}>
      <animated.div
        className="filler-box"
        style={{
          ...spring,
          //backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.49)), url(${project.node.frontmatter.image_list.image_1.publicURL})`,
        }}
      >
        <div
          onClick={handleShow}
          className={`project-title ${show && toggle ? "project-open" : ""}`}
        >
          <h2>{project.node.frontmatter.title}</h2>
          <div className="caret">
            <Caret />
          </div>
        </div>
      </animated.div>
      <Concertina open={show && toggle ? true : false}>
        <div className="project-page">
          <h3>
            {project.node.frontmatter.client !== "N/A"
              ? `Client: ${project.node.frontmatter.client}`
              : null}
          </h3>
          <h3>
            {project.node.frontmatter.budget !== "N/A"
              ? `Budget: ${project.node.frontmatter.budget}`
              : null}
          </h3>
          <h3>
            {project.node.frontmatter.location !== "N/A"
              ? `Location: ${project.node.frontmatter.location}`
              : null}
          </h3>
          <h3>
            {project.node.frontmatter.date !== "N/A"
              ? `Completion: ${project.node.frontmatter.date}`
              : null}
          </h3>
          <br />
          <Carousel
            images={project.node.frontmatter.image_list}
            video={
              project.node.frontmatter.video !== null
                ? project.node.frontmatter.video.publicURL
                : null
            }
          />
          <br />
          <div
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{
              __html: project.node.html,
            }}
          />
          <br />
          <div>
            <p style={{ lineHeight: "1.25" }}>
              <em>{project.node.frontmatter.awards}</em>
            </p>
          </div>
        </div>
      </Concertina>
    </div>
  );
};

export default Project;
