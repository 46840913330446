import React, { useState, useRef } from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactModal from "react-modal";
import useScrollBlock from "../Hooks/useScrollBlock";

const Carousel = ({ images, video }) => {
  return (
    <div className="image-grid">
      {Object.entries(images).map((image, i) => {
        const pic = getImage(image[1]);
        if (pic !== undefined) {
          return <ImageExplorer pic={pic} key={i} />;
        } else return null;
      })}

      {video ? <VideoExplorer video={video} pic={images.image_1} /> : null}
    </div>
  );
};

export default Carousel;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
  },
};

const ImageExplorer = ({ pic }) => {
  const [fullscreen, makeFullscreen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  return (
    <div>
      <button onClick={() => makeFullscreen(!fullscreen)}>
        <GatsbyImage
          image={pic}
          alt="this image has no description"
          style={{ maxHeight: "220px" }}
          imgStyle={{ objectFit: "contain", width: "auto" }}
        />
      </button>
      <ReactModal
        isOpen={fullscreen}
        onAfterOpen={blockScroll}
        onRequestClose={allowScroll()}
        contentLabel="Image Viewer"
        style={customStyles}
      >
        <div
          style={{
            width: "fit-content",
            margin: "auto",
          }}
          onClick={() => makeFullscreen(false)}
        >
          <GatsbyImage image={pic} alt="this image has no description" />
          <div
            style={{
              position: "absolute",
              top: "30px",
              right: "30px",
              cursor: "pointer",
              zIndex: "10",
            }}
          >
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 2L29 29M29 2L2 29" stroke="black" strokeWidth="4" />
            </svg>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

const VideoExplorer = ({ pic, video }) => {
  const videoRef = useRef(null);
  const [fullscreen, makeFullscreen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  function handleClose() {
    makeFullscreen(false);
    videoRef.current.pause();
  }

  function handleOpen() {
    makeFullscreen(!fullscreen);
    setTimeout(() => {
      videoRef.current.play();
    }, 700);
  }

  return (
    <div>
      <button onClick={handleOpen} style={{ position: "relative" }}>
        <GatsbyImage
          style={{ position: "relative" }}
          image={getImage(pic)}
          alt="this image has no description"
        />
        <div
          style={{
            position: "absolute",
            inset: "0",
            width: "100%",
            height: "100%",
          }}
        >
          <svg
            style={{ position: "relative", top: "23%" }}
            width="50%"
            height="50%"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 44L24 50L54 34.8938L24 20L24 26L24 44ZM29 41.4453L41.8222 34.8938L29 28.4951V41.4453Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M35 64C51.0163 64 64 51.0163 64 35C64 18.9837 51.0163 6 35 6C18.9837 6 6 18.9837 6 35C6 51.0163 18.9837 64 35 64ZM35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
              fill="white"
            />
          </svg>
        </div>
      </button>
      <ReactModal
        isOpen={fullscreen}
        onAfterOpen={blockScroll}
        onRequestClose={allowScroll()}
        contentLabel="Video Viewer"
        style={customStyles}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
          }}
        >
          <video
            ref={videoRef}
            width="80%"
            height="80%"
            controls
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div style={{ top: "0" }} onClick={handleClose}>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 2L29 29M29 2L2 29" stroke="black" strokeWidth="4" />
            </svg>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};
